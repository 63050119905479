@import url("./theme/light.scss");
@import url("./theme/dark.scss");
@import url("./components/feedback.scss");
@import url("./theme/animation.scss");

body {
    font-family: "Inter", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    line-height: 0 !important;
    font-size: 12px;
}

.MuiGrid-root {
    margin-top: 0 !important;
}

#main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    user-select: none;
    padding: 10px;

    &-content {
        display: inline-flex;
        flex: auto;
        padding: 10px 0 0;
    }
}
