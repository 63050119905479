.flipOut {
    animation: flipOut 0.2s;
}

.miss-char,
.present-char,
.match-char {
    animation: flip 0.5s ease-in;
}

.win-char {
    animation: bounce 1s ease-in;
}

@keyframes flipOut {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    40% {
        transform: scale(1.1);
        opacity: 1;
    }
}

@keyframes flip {
    0% {
        transform: rotateX(0);
    }
    50% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateX(0);
    }
}

@keyframes bounce {
    0%,
    20% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    50% {
        transform: translateY(5px);
    }
    60% {
        transform: translateY(-15px);
    }
    80% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
    }
}
