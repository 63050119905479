.bundle-loading-box {
    height: 19px;
    border-radius: 4px;
    padding-right: 8px;
    display: block;

    &-progress {
        border-radius: 4px;
        height: 100% !important;
    }
}
